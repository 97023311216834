<template>
  <div class="tariff-spec">
    <div class="tariff-spec__content">
      <base-specs :list="list" class="standart-text" />
    </div>
  </div>
</template>

<script>
import BaseSpecs from '@/components/Specs/BaseSpecs';
import specsFormatting from '@/mixins/specsFormatting';
export default {
  name: 'TariffSpec',
  components: { BaseSpecs },
  mixins: [specsFormatting],
  props: {
    cost: {
      type: Number,
      required: true,
      //validator: obj => isNumber(obj.value) && obj.unit,
    },
    datacentername: {
      type: String,
      default: '',
    },
  },
  computed: {
    list() {
      let arr = [];
      if (this.datacentername) arr.push(this.datacentername);
      if (this.cost || this.cost === 0) arr.push(this.$n(this.cost, 'currency', this.$i18n.locale));
      return arr;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-spec {
  +breakpoint(sm-and-up) {
    flexy(flex-start, center);
  }
  &__os-logo {
    flex: 0 0 auto;
    +breakpoint(sm-and-up) {
      margin-right: 1.5rem;
    }
  }
  &__os-name {
    margin-bottom: 0.25rem;
  }
}
</style>
